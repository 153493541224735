import {Injectable} from '@angular/core';

declare const gtag: any;

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    constructor() {
    }

    allowCookies() {
        const gtmScript = document.createElement('script');
        gtmScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-E2QPWG92DR');
        gtmScript.setAttribute('id', 'gtmScript');
        document.head.appendChild(gtmScript);
        gtmScript.onload = () => {
            gtag('js', new Date());
            gtag('config', 'G-E2QPWG92DR');
        };

    }

    removeCookies() {
        const gtmScript = document.getElementById('gtmScript');
        if (gtmScript) {
            gtmScript.parentNode.removeChild(gtmScript);
        }
        const theCookies = document.cookie.split(';');
        for (const cookie of theCookies) {
            const cookieName = cookie.split('=')[0];
            if (cookieName.indexOf('_ga') > -1) {
                this.deleteCookie(cookieName);
            }
        }
    }

    getCookie(name) {
        return document.cookie.split(';').filter(c => {
            return c.trim().startsWith(name + '=');
        });
    }

    private deleteCookie(name) {
        if (this.getCookie(name)) {
            document.cookie = name + '=' +
                ';path=/;domain=.faircheck.at;expires=Thu, 01 Jan 1970 00:00:01 GMT';
        }
    }
}
