/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OdataTermineResponse } from '../models/odata-termine-response';
import { OdataTermineRequest } from '../models/odata-termine-request';
import { OdataTermineAktRequest } from '../models/odata-termine-akt-request';
import { FairAppSimpleRequest } from '../models/fair-app-simple-request';
import { OdataTerminCreateResponse } from '../models/odata-termin-create-response';
import { OdataTerminCreateRequest } from '../models/odata-termin-create-request';
@Injectable({
  providedIn: 'root',
})
class TermineService extends __BaseService {
  static readonly termineOdataCreatePath = '/termine/odata/';
  static readonly termineOdataAktCreatePath = '/termine/odata/akt';
  static readonly termineOdataAktBerichtCreatePath = '/termine/odata/akt/bericht';
  static readonly termineOdataCreateCreatePath = '/termine/odata/create';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  termineOdataCreateResponse(data: OdataTermineRequest): __Observable<__StrictHttpResponse<Array<OdataTermineResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/termine/odata/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OdataTermineResponse>>;
      })
    );
  }
  /**
   * @param data undefined
   */
  termineOdataCreate(data: OdataTermineRequest): __Observable<Array<OdataTermineResponse>> {
    return this.termineOdataCreateResponse(data).pipe(
      __map(_r => _r.body as Array<OdataTermineResponse>)
    );
  }

  /**
   * @param data undefined
   */
  termineOdataAktCreateResponse(data: OdataTermineAktRequest): __Observable<__StrictHttpResponse<OdataTermineAktRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/termine/odata/akt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataTermineAktRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  termineOdataAktCreate(data: OdataTermineAktRequest): __Observable<OdataTermineAktRequest> {
    return this.termineOdataAktCreateResponse(data).pipe(
      __map(_r => _r.body as OdataTermineAktRequest)
    );
  }

  /**
   * @param data undefined
   */
  termineOdataAktBerichtCreateResponse(data: FairAppSimpleRequest): __Observable<__StrictHttpResponse<FairAppSimpleRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/termine/odata/akt/bericht`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FairAppSimpleRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  termineOdataAktBerichtCreate(data: FairAppSimpleRequest): __Observable<FairAppSimpleRequest> {
    return this.termineOdataAktBerichtCreateResponse(data).pipe(
      __map(_r => _r.body as FairAppSimpleRequest)
    );
  }

  /**
   * @param data undefined
   */
  termineOdataCreateCreateResponse(data: OdataTerminCreateRequest): __Observable<__StrictHttpResponse<OdataTerminCreateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/termine/odata/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataTerminCreateResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  termineOdataCreateCreate(data: OdataTerminCreateRequest): __Observable<OdataTerminCreateResponse> {
    return this.termineOdataCreateCreateResponse(data).pipe(
      __map(_r => _r.body as OdataTerminCreateResponse)
    );
  }
}

module TermineService {
}

export { TermineService }
