/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class CreateSessionService extends __BaseService {
  static readonly createSessionListPath = '/create_session/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CreateSessionService.CreateSessionListParams` containing the following parameters:
   *
   * - `short`: short
   *
   * - `appointment`: appointment
   *
   * - `akt_id`: akt_id
   */
  createSessionListResponse(params: CreateSessionService.CreateSessionListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.short != null) __params = __params.set('short', params.short.toString());
    if (params.appointment != null) __params = __params.set('appointment', params.appointment.toString());
    if (params.aktId != null) __params = __params.set('akt_id', params.aktId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/create_session/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CreateSessionService.CreateSessionListParams` containing the following parameters:
   *
   * - `short`: short
   *
   * - `appointment`: appointment
   *
   * - `akt_id`: akt_id
   */
  createSessionList(params: CreateSessionService.CreateSessionListParams): __Observable<null> {
    return this.createSessionListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CreateSessionService {

  /**
   * Parameters for createSessionList
   */
  export interface CreateSessionListParams {

    /**
     * short
     */
    short?: string;

    /**
     * appointment
     */
    appointment?: string;

    /**
     * akt_id
     */
    aktId?: string;
  }
}

export { CreateSessionService }
