import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-termin-bearbeiten",
  templateUrl: "./termin-bearbeiten.component.html",
  styleUrls: ["./termin-bearbeiten.component.scss"],
})
export class TerminBearbeitenComponent implements OnInit {
  @Input() akt: any;
  @Input() isTechnik: boolean;
  constructor() {}

  ngOnInit() {}
}
