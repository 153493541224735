// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fai-link ion-button {
  margin-left: auto;
  margin-right: auto;
  min-width: 55%;
}

.mat-column-Beginn {
  width: 160px;
  max-width: 160px;
}

.mat-column-Terminart {
  width: 160px;
  max-width: 160px;
}

.mat-column-Erledigt {
  width: 90px;
  max-width: 90px;
}

.mat-column-Status {
  width: 90px;
  max-width: 90px;
}`, "",{"version":3,"sources":["webpack://./src/app/details/details.page.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF","sourcesContent":[".fai-link ion-button {\n  margin-left: auto;\n  margin-right: auto;\n  min-width: 55%;\n}\n\n.mat-column-Beginn {\n  width: 160px;\n  max-width: 160px;\n}\n\n.mat-column-Terminart {\n  width: 160px;\n  max-width: 160px;\n}\n\n.mat-column-Erledigt {\n  width: 90px;\n  max-width: 90px;\n}\n\n.mat-column-Status {\n  width: 90px;\n  max-width: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
