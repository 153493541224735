import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { SuchePageRoutingModule } from "./suche-routing.module";

import { SuchePage } from "./suche.page";
import { HttpClientModule } from "@angular/common/http";
import { FacetComponent } from "./facet/facet.component";
import { BrowserModule } from "@angular/platform-browser";
import { UtilModule } from "../../util/util.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SuchePageRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    UtilModule,
  ],
  declarations: [SuchePage, FacetComponent],
})
export class SuchePageModule {}
