import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {KalkulationsPosition} from '../models/kalkulationsposition';
import {ModalController} from '@ionic/angular';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-position-modal',
  templateUrl: './position-modal.page.html',
  styleUrls: ['./position-modal.page.scss'],
})
export class PositionModalPage implements OnInit {
  index;
  initialPosition;
  formGroup: UntypedFormGroup;
  position: KalkulationsPosition;
  positionen: KalkulationsPosition[];
  summeNetto;
  summeBrutto;
  summeSteuern;
  isMobile;
  isEdit = false;
  isNew = false;
  aktid;
  snr;
  isForAkt;


  constructor(
      private fb: UntypedFormBuilder,
      private modalController: ModalController, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.aktid = this.route.snapshot.queryParams.aktid;
    this.snr = this.route.snapshot.queryParams.snr;
    this.isForAkt = (this.aktid && this.snr);
    this.positionen = this.allePositionen;
    if (this.index >= 0) {
      this.position = this.positionen[this.index];
      this.isEdit = true;
    } else {
      this.position = this.initialPosition;
      this.isNew = true;
    }
    this.formGroup = this.fb.group({
      Positionsgruppe: [this.position.Positionsgruppe],
      Untergruppe: [this.position.Untergruppe],
      Position: [this.position.Position],
      Information: [this.position.Information],
      Menge: [this.position.Menge],
      nettoPreisOverride: [this.position.nettoPreisOverride ? this.position.nettoPreisOverride : this.position.nettoPreis],
      gesamtpreisNetto: [this.position.Menge * this.position.nettoPreis],
      Einheit: [this.position.Einheit],
      EH: [this.position.EH],
      Quelle: [this.position.Quelle],
      HK: [this.position.HK],
      PosNr: [this.position.PosNr],
      Versicherungsunternehmen: [this.position.Versicherungsunternehmen ? this.position.Versicherungsunternehmen.toString() : (this.position.AlleVus ? 'Alle' : '')],
      Summe: [this.summeNetto]
    });

    this.calculateSumme();

    this.formGroup.valueChanges.subscribe(() => {
      this.calculateSumme();
    });
  }


  onSubmit() {
    if (this.isEdit) {
      this.positionen[this.index] = {...this.position, ...this.formGroup.value};
    } else if (this.isNew) {
      this.positionen.push({...this.position, ...this.formGroup.value});
    }
    if (this.isForAkt) {
      localStorage.setItem('gesamtkalkulation_' + this.aktid, JSON.stringify(this.positionen));
    } else {
      localStorage.setItem('gesamtkalkulation', JSON.stringify(this.positionen));
    }
    this.modalController.dismiss();
  }

  get allePositionen(): KalkulationsPosition[] {
    let positionenJSON;
    if (this.isForAkt) {
      positionenJSON = localStorage.getItem('gesamtkalkulation_' + this.aktid);
    } else {
      positionenJSON = localStorage.getItem('gesamtkalkulation');
    }
    let positionen = [];
    if (positionenJSON) {
      positionen = JSON.parse(positionenJSON);
    }
    return positionen;
  }

  calculateSumme() {
    this.summeNetto = (this.formGroup.controls.nettoPreisOverride.value * this.formGroup.controls.Menge.value);
    this.summeSteuern = (this.formGroup.controls.nettoPreisOverride.value * this.position.Ust * this.formGroup.controls.Menge.value);
    this.summeBrutto = this.summeNetto + this.summeSteuern;

  }

  cancel() {
    this.modalController.dismiss();
  }
}

