/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AbloeseAktDataRetrieveResponse } from '../models/abloese-akt-data-retrieve-response';
import { AbloeseAktDataSaveRequest } from '../models/abloese-akt-data-save-request';
import { FairAppRequest } from '../models/fair-app-request';
@Injectable({
  providedIn: 'root',
})
class AbloeseService extends __BaseService {
  static readonly abloeseCreatePath = '/abloese/';
  static readonly abloeseDeletePath = '/abloese/';
  static readonly abloesePdfReadPath = '/abloese/pdf/{id}/{secret}/';
  static readonly abloeseRetrieveCreatePath = '/abloese/retrieve';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  abloeseCreateResponse(data: AbloeseAktDataSaveRequest): __Observable<__StrictHttpResponse<AbloeseAktDataRetrieveResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/abloese/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AbloeseAktDataRetrieveResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  abloeseCreate(data: AbloeseAktDataSaveRequest): __Observable<AbloeseAktDataRetrieveResponse> {
    return this.abloeseCreateResponse(data).pipe(
      __map(_r => _r.body as AbloeseAktDataRetrieveResponse)
    );
  }

  /**
   * @param data undefined
   */
  abloeseDeleteResponse(data: FairAppRequest): __Observable<__StrictHttpResponse<AbloeseAktDataRetrieveResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/abloese/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AbloeseAktDataRetrieveResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  abloeseDelete(data: FairAppRequest): __Observable<AbloeseAktDataRetrieveResponse> {
    return this.abloeseDeleteResponse(data).pipe(
      __map(_r => _r.body as AbloeseAktDataRetrieveResponse)
    );
  }

  /**
   * @param params The `AbloeseService.AbloesePdfReadParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `id`:
   */
  abloesePdfReadResponse(params: AbloeseService.AbloesePdfReadParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/abloese/pdf/${encodeURIComponent(params.id)}/${encodeURIComponent(params.secret)}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `AbloeseService.AbloesePdfReadParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `id`:
   */
  abloesePdfRead(params: AbloeseService.AbloesePdfReadParams): __Observable<Blob> {
    return this.abloesePdfReadResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param data undefined
   */
  abloeseRetrieveCreateResponse(data: FairAppRequest): __Observable<__StrictHttpResponse<AbloeseAktDataRetrieveResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/abloese/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AbloeseAktDataRetrieveResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  abloeseRetrieveCreate(data: FairAppRequest): __Observable<AbloeseAktDataRetrieveResponse> {
    return this.abloeseRetrieveCreateResponse(data).pipe(
      __map(_r => _r.body as AbloeseAktDataRetrieveResponse)
    );
  }
}

module AbloeseService {

  /**
   * Parameters for abloesePdfRead
   */
  export interface AbloesePdfReadParams {
    secret: string;
    id: string;
  }
}

export { AbloeseService }
