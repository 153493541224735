import { Injectable } from "@angular/core";
import mime from "mime";
import { replaceFileExtensionConditionally } from "../util/global-functions/global-functions";

@Injectable({ providedIn: "root" })
export class DownloadService {
  createLinkAndDownloadFile(file: Blob, filename) {
    const downloadAnchor = document.createElement("a");
    downloadAnchor.style.display = "none";

    downloadAnchor.href = this.getDownloadedFileUrl(file);
    downloadAnchor.download = filename;
    downloadAnchor.click();
  }

  getDownloadedFileUrl(file: Blob): string {
    return URL.createObjectURL(file);
  }

  private isValidBase64 = (str: string): boolean => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  getDownloadedFileBlobByString(
    fileDataString: string,
    rawFileName: string,
  ): Blob {
    const fileName = this.getFileNameFromPath(rawFileName);
    let data: string;

    if (!this.isValidBase64(fileDataString)) {
      data = atob(fileDataString.replace(/-/g, "+").replace(/_/g, "/"));
    } else {
      data = atob(fileDataString);
    }

    const uint8Array = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
      uint8Array[i] = data.charCodeAt(i);
    }

    return new Blob([uint8Array], {
      type: this.getFileMimeType(fileName),
    });
  }

  handleDownloadedFile(fileDataString: string, rawFileName: string) {
    const fileName = replaceFileExtensionConditionally(
      this.getFileNameFromPath(rawFileName),
    );
    const blob = this.getDownloadedFileBlobByString(
      fileDataString,
      rawFileName,
    );

    this.createLinkAndDownloadFile(blob, fileName);
  }

  getFileMimeType(filename: string): string | undefined {
    const extension = filename.split(".").pop()?.toLowerCase();
    if (!extension) return undefined;

    const mimeTypeFromExtension = mime.getType(extension);
    if (mimeTypeFromExtension) {
      return mimeTypeFromExtension;
    }
    return "application/octet-stream";
  }

  isImageFile(type: string): boolean {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];

    if (!type) {
      return false;
    }

    const fileExtension = type.split(".").pop()?.toLowerCase();
    return !!fileExtension && imageExtensions.includes(fileExtension);
  }

  getFileNameFromPath(path: string): string {
    return path?.split("\\").pop() ?? "";
  }
}
