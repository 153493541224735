// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reihenfolge-uebernehmen-button-container {
  display: flex;
  flex-direction: column;
}

.mat-column-sort {
  width: 80px;
  max-width: 80px;
  word-wrap: break-word;
}

.mat-column-art {
  width: 80px;
  max-width: 80px;
  word-wrap: break-word;
}

.mat-column-datum {
  width: 120px;
  max-width: 120px;
  word-wrap: break-word;
}

.mat-column-adressattext {
  width: 140px;
  max-width: 140px;
  word-wrap: break-word;
}

.mat-column-kategorie {
  width: 120px;
  max-width: 120px;
  word-wrap: break-word;
}

.mat-column-quelle {
  width: 120px;
  max-width: 120px;
  word-wrap: break-word;
}

.mat-column-PreviewButtons {
  width: 90px;
  max-width: 90px;
}

.mat-column-DeleteButtons {
  width: 90px;
  max-width: 90px;
}`, "",{"version":3,"sources":["webpack://./src/app/util/file-overview/file-overview.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,qBAAA;AACF;;AACA;EACE,WAAA;EACA,eAAA;EACA,qBAAA;AAEF;;AAAA;EACE,YAAA;EACA,gBAAA;EACA,qBAAA;AAGF;;AADA;EACE,YAAA;EACA,gBAAA;EACA,qBAAA;AAIF;;AAFA;EACE,YAAA;EACA,gBAAA;EACA,qBAAA;AAKF;;AAHA;EACE,YAAA;EACA,gBAAA;EACA,qBAAA;AAMF;;AAJA;EACE,WAAA;EACA,eAAA;AAOF;;AALA;EACE,WAAA;EACA,eAAA;AAQF","sourcesContent":[".reihenfolge-uebernehmen-button-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.mat-column-sort {\n  width: 80px;\n  max-width: 80px;\n  word-wrap: break-word;\n}\n.mat-column-art {\n  width: 80px;\n  max-width: 80px;\n  word-wrap: break-word;\n}\n.mat-column-datum {\n  width: 120px;\n  max-width: 120px;\n  word-wrap: break-word;\n}\n.mat-column-adressattext {\n  width: 140px;\n  max-width: 140px;\n  word-wrap: break-word;\n}\n.mat-column-kategorie {\n  width: 120px;\n  max-width: 120px;\n  word-wrap: break-word;\n}\n.mat-column-quelle {\n  width: 120px;\n  max-width: 120px;\n  word-wrap: break-word;\n}\n.mat-column-PreviewButtons {\n  width: 90px;\n  max-width: 90px;\n}\n.mat-column-DeleteButtons {\n  width: 90px;\n  max-width: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
