/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DetailAktDataRetrieveResponse } from '../models/detail-akt-data-retrieve-response';
import { AktListRetrieveRequest } from '../models/akt-list-retrieve-request';
@Injectable({
  providedIn: 'root',
})
class ListService extends __BaseService {
  static readonly listRetrieveCreatePath = '/list/retrieve';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  listRetrieveCreateResponse(data: AktListRetrieveRequest): __Observable<__StrictHttpResponse<Array<DetailAktDataRetrieveResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/list/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DetailAktDataRetrieveResponse>>;
      })
    );
  }
  /**
   * @param data undefined
   */
  listRetrieveCreate(data: AktListRetrieveRequest): __Observable<Array<DetailAktDataRetrieveResponse>> {
    return this.listRetrieveCreateResponse(data).pipe(
      __map(_r => _r.body as Array<DetailAktDataRetrieveResponse>)
    );
  }
}

module ListService {
}

export { ListService }
