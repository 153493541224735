export function getLowerCaseInputValue(input: string): string {
  return input ? input.toLowerCase() : null;
}

export function getStringFilterConditionForDatatable(
  datavalue: string,
  filtervalue: string,
): boolean {
  return (
    (datavalue !== null &&
      filtervalue !== null &&
      datavalue.toLowerCase().indexOf(filtervalue) !== -1) ||
    !filtervalue
  );
}

export function replaceFileExtensionConditionally(fileName: string): string {
  if (fileName.endsWith(".pms")) {
    return fileName.slice(0, -4) + ".eml";
  }

  return fileName;
}
