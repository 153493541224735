/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FairAppSimplestRequest } from '../models/fair-app-simplest-request';
import { MailmanagerMailboxEmailContentRequest } from '../models/mailmanager-mailbox-email-content-request';
import { MailmanagerMailboxEmailAssignRequest } from '../models/mailmanager-mailbox-email-assign-request';
import { MailmanagerMailboxEmailsRequest } from '../models/mailmanager-mailbox-emails-request';
import { MailmanagerMailboxFoldersRequest } from '../models/mailmanager-mailbox-folders-request';
@Injectable({
  providedIn: 'root',
})
class MailmanagerService extends __BaseService {
  static readonly mailmanagerMailboxAllFoldersRetrieveCreatePath = '/mailmanager/mailbox/all-folders/retrieve';
  static readonly mailmanagerMailboxEmailContentRetrieveCreatePath = '/mailmanager/mailbox/email-content/retrieve';
  static readonly mailmanagerMailboxEmailAssignCreatePath = '/mailmanager/mailbox/email/assign';
  static readonly mailmanagerMailboxEmailsRetrieveCreatePath = '/mailmanager/mailbox/emails/retrieve';
  static readonly mailmanagerMailboxFoldersRetrieveCreatePath = '/mailmanager/mailbox/folders/retrieve';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  mailmanagerMailboxAllFoldersRetrieveCreateResponse(data: FairAppSimplestRequest): __Observable<__StrictHttpResponse<FairAppSimplestRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mailmanager/mailbox/all-folders/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FairAppSimplestRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  mailmanagerMailboxAllFoldersRetrieveCreate(data: FairAppSimplestRequest): __Observable<FairAppSimplestRequest> {
    return this.mailmanagerMailboxAllFoldersRetrieveCreateResponse(data).pipe(
      __map(_r => _r.body as FairAppSimplestRequest)
    );
  }

  /**
   * @param data undefined
   */
  mailmanagerMailboxEmailContentRetrieveCreateResponse(data: MailmanagerMailboxEmailContentRequest): __Observable<__StrictHttpResponse<MailmanagerMailboxEmailContentRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mailmanager/mailbox/email-content/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MailmanagerMailboxEmailContentRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  mailmanagerMailboxEmailContentRetrieveCreate(data: MailmanagerMailboxEmailContentRequest): __Observable<MailmanagerMailboxEmailContentRequest> {
    return this.mailmanagerMailboxEmailContentRetrieveCreateResponse(data).pipe(
      __map(_r => _r.body as MailmanagerMailboxEmailContentRequest)
    );
  }

  /**
   * @param data undefined
   */
  mailmanagerMailboxEmailAssignCreateResponse(data: MailmanagerMailboxEmailAssignRequest): __Observable<__StrictHttpResponse<MailmanagerMailboxEmailAssignRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mailmanager/mailbox/email/assign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MailmanagerMailboxEmailAssignRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  mailmanagerMailboxEmailAssignCreate(data: MailmanagerMailboxEmailAssignRequest): __Observable<MailmanagerMailboxEmailAssignRequest> {
    return this.mailmanagerMailboxEmailAssignCreateResponse(data).pipe(
      __map(_r => _r.body as MailmanagerMailboxEmailAssignRequest)
    );
  }

  /**
   * @param data undefined
   */
  mailmanagerMailboxEmailsRetrieveCreateResponse(data: MailmanagerMailboxEmailsRequest): __Observable<__StrictHttpResponse<MailmanagerMailboxEmailsRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mailmanager/mailbox/emails/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MailmanagerMailboxEmailsRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  mailmanagerMailboxEmailsRetrieveCreate(data: MailmanagerMailboxEmailsRequest): __Observable<MailmanagerMailboxEmailsRequest> {
    return this.mailmanagerMailboxEmailsRetrieveCreateResponse(data).pipe(
      __map(_r => _r.body as MailmanagerMailboxEmailsRequest)
    );
  }

  /**
   * @param data undefined
   */
  mailmanagerMailboxFoldersRetrieveCreateResponse(data: MailmanagerMailboxFoldersRequest): __Observable<__StrictHttpResponse<MailmanagerMailboxFoldersRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mailmanager/mailbox/folders/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MailmanagerMailboxFoldersRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  mailmanagerMailboxFoldersRetrieveCreate(data: MailmanagerMailboxFoldersRequest): __Observable<MailmanagerMailboxFoldersRequest> {
    return this.mailmanagerMailboxFoldersRetrieveCreateResponse(data).pipe(
      __map(_r => _r.body as MailmanagerMailboxFoldersRequest)
    );
  }
}

module MailmanagerService {
}

export { MailmanagerService }
