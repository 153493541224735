import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { ActivatedRoute, Router } from "@angular/router";
import { UserRole } from "../util/enums/global-enums";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  authGuardLogic() {
    return this.oidcSecurityService.checkAuth().pipe(
      map((auth) => {
        if (!auth) {
          if (
            window.location.pathname !== "/" &&
            window.location.search === ""
          ) {
            localStorage.setItem(
              "redirectAfterLogin",
              window.location.pathname,
            );
          }
          this.oidcSecurityService.authorize();
        }
        if (auth) {
          const allowedRoles = Object.values(UserRole).map((role) =>
            role.toString(),
          );
          const currentRole = this.getCurrentUserRole();
          if (allowedRoles.indexOf(currentRole) <= -1) {
            alert(`Rolle "${currentRole}" wird nicht unterstützt`);
            return false;
          } else if (localStorage.getItem("redirectAfterLogin") !== null) {
            const redirectAfterLogin =
              localStorage.getItem("redirectAfterLogin");
            localStorage.removeItem("redirectAfterLogin");
            this.router.navigate([redirectAfterLogin]);
          }
        }
        return auth;
      }),
    );
  }

  getCurrentUserRole(): string {
    return this.oidcSecurityService.getPayloadFromIdToken()[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];
  }

  isUserPasswordLogin(): boolean {
    const queryParams = this.route.snapshot.queryParams;
    return queryParams.user && queryParams.password;
  }

  isCustomer(): boolean {
    return !this.hasToken() && !this.isUserPasswordLogin();
  }

  isRegulierer(): boolean {
    return this.hasToken() && this.getCurrentUserRole() === UserRole.Regulierer;
  }

  isFairApp(): boolean {
    const queryParams = this.route.snapshot.queryParams;
    return queryParams.fairApp;
  }

  hasCurrentUserAccessForRole(role: UserRole): boolean {
    switch (role) {
      case UserRole.Admin:
        return (
          this.hasToken() &&
          [UserRole.Admin]
            .map((role) => role.toString())
            .includes(this.getCurrentUserRole())
        );
      case UserRole.BackofficeAdmin:
        return (
          this.hasToken() &&
          [UserRole.Admin, UserRole.BackofficeAdmin]
            .map((role) => role.toString())
            .includes(this.getCurrentUserRole())
        );
      case UserRole.Backoffice:
        return (
          this.hasToken() &&
          [UserRole.Admin, UserRole.BackofficeAdmin, UserRole.Backoffice]
            .map((role) => role.toString())
            .includes(this.getCurrentUserRole())
        );
      case UserRole.Regulierer:
        return (
          this.hasToken() &&
          [
            UserRole.Admin,
            UserRole.BackofficeAdmin,
            UserRole.Backoffice,
            UserRole.Regulierer,
          ]
            .map((role) => role.toString())
            .includes(this.getCurrentUserRole())
        );
      case UserRole.Interessent:
        return (
          this.hasToken() &&
          [
            UserRole.Admin,
            UserRole.BackofficeAdmin,
            UserRole.Backoffice,
            UserRole.Regulierer,
            UserRole.Interessent,
          ]
            .map((role) => role.toString())
            .includes(this.getCurrentUserRole())
        );
      default:
        return false;
    }
  }

  hasToken(): boolean {
    return !!this.oidcSecurityService.getToken().length;
  }
}
