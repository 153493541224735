// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-group-inline {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.radio-group-inline ion-radio {
  flex: unset;
  width: unset;
}`, "",{"version":3,"sources":["webpack://./src/app/util/termin-bearbeiten/termin-bearbeiten.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,6BAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;AAEJ","sourcesContent":[".radio-group-inline {\n  display: flex;\n  justify-content: space-around;\n  width: 100%;\n  ion-radio {\n    flex: unset;\n    width: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
