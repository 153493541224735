import { Injectable } from "@angular/core";
import { SendService } from "../api/services/send.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { AlertController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class SmsService {
  constructor(
    private sendService: SendService,
    private oidcSecurityService: OidcSecurityService,
    private alertController: AlertController,
  ) {}

  private sendSms(aktid) {
    this.sendService
      .sendStatusCreate({
        akt_id: aktid,
        token: this.oidcSecurityService.getToken(),
        submit_type: "sms",
      })
      .subscribe(() => {
        alert("SMS gesendet");
      });
  }

  async sendSmsAlert(aktid) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Wollen Sie eine Statusinfo SMS verschicken?",
      message:
        "Bei Bestätigung wird eine SMS an den Versicherungsnehmer gesendet.",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "color-primary",
          handler: () => {},
        },
        {
          cssClass: "color-secondary",
          text: "Senden",
          handler: () => {
            this.sendSms(aktid);
          },
        },
      ],
    });

    await alert.present();
  }
}
