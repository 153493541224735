import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MailmanagerResolve {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    return this.oidcSecurityService.userData$.pipe(
      map((userData) => {
        return userData;
      }),
    );
  }
}
