/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AbloeseService } from './services/abloese.service';
import { AktService } from './services/akt.service';
import { AktsucheService } from './services/aktsuche.service';
import { AuthenticateService } from './services/authenticate.service';
import { ChecklisteService } from './services/checkliste.service';
import { ClearCacheService } from './services/clear-cache.service';
import { CreateSessionService } from './services/create-session.service';
import { DetailService } from './services/detail.service';
import { DokumenteService } from './services/dokumente.service';
import { FairpediaService } from './services/fairpedia.service';
import { GutachtenService } from './services/gutachten.service';
import { KontaktService } from './services/kontakt.service';
import { KundenprozessService } from './services/kundenprozess.service';
import { KundenstatuslisteService } from './services/kundenstatusliste.service';
import { ListService } from './services/list.service';
import { MailmanagerService } from './services/mailmanager.service';
import { PdfService } from './services/pdf.service';
import { ProxyService } from './services/proxy.service';
import { SachbearbeiterService } from './services/sachbearbeiter.service';
import { SendNachhaltigkeitsinfoService } from './services/send-nachhaltigkeitsinfo.service';
import { SendService } from './services/send.service';
import { StatusService } from './services/status.service';
import { TermineService } from './services/termine.service';
import { TextkorrekturService } from './services/textkorrektur.service';
import { TextvorlagenService } from './services/textvorlagen.service';
import { TitelService } from './services/titel.service';
import { UploadService } from './services/upload.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AbloeseService,
    AktService,
    AktsucheService,
    AuthenticateService,
    ChecklisteService,
    ClearCacheService,
    CreateSessionService,
    DetailService,
    DokumenteService,
    FairpediaService,
    GutachtenService,
    KontaktService,
    KundenprozessService,
    KundenstatuslisteService,
    ListService,
    MailmanagerService,
    PdfService,
    ProxyService,
    SachbearbeiterService,
    SendNachhaltigkeitsinfoService,
    SendService,
    StatusService,
    TermineService,
    TextkorrekturService,
    TextvorlagenService,
    TitelService,
    UploadService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
