// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  restRootUrl: 'https://api-test.faircheck.at',
  clientId: 'fairapp',
  redirectUrl: 'https://fairapptest.faircheck.at',
  identityServer: 'https://identity-test.faircheck.at',
  fairmapUrl: 'https://fairmaptest.faircheck.at/index.html',
  vexportalBackend: 'https://clientstest.vex-portal.com/',
  vexportalWeb: 'https://apptest.vex-portal.com/info/fairVEX/',
  vexApps: 'https://appstest.vex-portal.com/vex/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
