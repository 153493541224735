import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {SolrQueryResponse} from '../../models/solrqueryresponse';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'app-facet',
    templateUrl: './facet.component.html',
    styleUrls: ['./facet.component.scss'],
})
export class FacetComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    facetField: string;

    @Input()
    solrResponse: SolrQueryResponse;

    @Input()
    fc: UntypedFormControl;

    @Input()
    expandedFacets: { [key: string]: boolean };

    get selectedValue(): string {
        let selectedValue = '';
        this.solrResponseFacetField?.forEach((e, i) => {
            if (i % 2 === 0 && this.fc.value.indexOf(e) !== -1) {
                selectedValue += e + ', ';
            }
        });
        if (selectedValue) {
            selectedValue = selectedValue.substr(0, selectedValue.length - 2);
        }
        return selectedValue;
    }

    get solrResponseFacetField() {
        return this.solrResponse?.facet_counts.facet_fields[this.facetField];
    }

    get expanded() {
        return this.expandedFacets[this.facetField];
    }

    set expanded(value: boolean) {
        this.expandedFacets[this.facetField] = value;
    }

    constructor() {
    }

    ngOnInit() {
    }

    onCheckboxChanged($event: any) {
        const value = $event.detail.value;
        const checked = $event.detail.checked;
        const currentValue = this.fc.value as string[];
        if (checked) {
            currentValue.push(value);
            this.fc.setValue(currentValue);
        } else {
            this.fc.setValue(currentValue.filter((e) => {
                return e !== value;
            }));
        }
    }
}
