/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FairAppSimplestRequest } from '../models/fair-app-simplest-request';
import { SendOwncloudNotification } from '../models/send-owncloud-notification';
import { SendStatus } from '../models/send-status';
@Injectable({
  providedIn: 'root',
})
class SendService extends __BaseService {
  static readonly sendKundenstatusinfoNotificationCreatePath = '/send/kundenstatusinfo_notification/';
  static readonly sendOwncloudNotificationCreatePath = '/send/owncloud_notification/';
  static readonly sendStatusCreatePath = '/send/status/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  sendKundenstatusinfoNotificationCreateResponse(data: FairAppSimplestRequest): __Observable<__StrictHttpResponse<FairAppSimplestRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/send/kundenstatusinfo_notification/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FairAppSimplestRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  sendKundenstatusinfoNotificationCreate(data: FairAppSimplestRequest): __Observable<FairAppSimplestRequest> {
    return this.sendKundenstatusinfoNotificationCreateResponse(data).pipe(
      __map(_r => _r.body as FairAppSimplestRequest)
    );
  }

  /**
   * @param data undefined
   */
  sendOwncloudNotificationCreateResponse(data: SendOwncloudNotification): __Observable<__StrictHttpResponse<SendOwncloudNotification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/send/owncloud_notification/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendOwncloudNotification>;
      })
    );
  }
  /**
   * @param data undefined
   */
  sendOwncloudNotificationCreate(data: SendOwncloudNotification): __Observable<SendOwncloudNotification> {
    return this.sendOwncloudNotificationCreateResponse(data).pipe(
      __map(_r => _r.body as SendOwncloudNotification)
    );
  }

  /**
   * @param data undefined
   */
  sendStatusCreateResponse(data: SendStatus): __Observable<__StrictHttpResponse<SendStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/send/status/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendStatus>;
      })
    );
  }
  /**
   * @param data undefined
   */
  sendStatusCreate(data: SendStatus): __Observable<SendStatus> {
    return this.sendStatusCreateResponse(data).pipe(
      __map(_r => _r.body as SendStatus)
    );
  }
}

module SendService {
}

export { SendService }
